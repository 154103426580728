import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.inputTarget.dataset.listener = "true";
  }

  upload() {
    if (!this.inputTarget.files.length || this.inputTarget.dataset.uploading) return;

    this.inputTarget.dataset.uploading = "true";
    this.element.requestSubmit(); // Submits the form
    this.inputTarget.value = ""; // Reset input field
    this.inputTarget.dataset.uploading = "";
  }
}
